import React from "react";
import Spacing from "../Spacing";
import HeroStyle2 from "../Hero/HeroStyle2";
import Brands from "../Brands";
import Marquee from "../Marquee";
import Cta from "../Cta";
import SectionHeading from "../SectionHeading";
import Accordion from "../Accordion";
import ServiceSlider from "../Slider/ServiceSlider";
import AboutStyle2 from "../About/AboutStyle2";
import FunFact from "../FunFact";
import IconBoxStyle2 from "../IconBox/IconBoxStyle2";
import { pageTitle } from "../../helpers/PageTitle";
import { Dialog } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const servideData = [
  {
    iconSrc: "/images/marketing-agency/service_icon_1.svg",
    title: "Uncover Title Brands",
    subTitle: "Avoid risks associated with irreparable car",
    btnText: "Check Vin",
    btnUrl: "/",
  },
  {
    iconSrc: "/images/marketing-agency/service_icon_2.svg",
    title: "Check Registration Records",
    subTitle: "Track registration history and issuing jurisdiction",
    btnText: "Check Vin",
    btnUrl: "/",
  },
  {
    iconSrc: "/images/marketing-agency/service_icon_3.svg",
    title: "Verify Mileage History",
    subTitle: "Check any signs of odometer rollback or tampering",
    btnText: "Check Vin",
    btnUrl: "/",
  },
  {
    iconSrc: "/images/marketing-agency/service_icon_4.svg",
    title: "Flag Stolen Vehicles",
    subTitle: "Detect active and previous theft recoveries.",
    btnText: "Check Vin",
    btnUrl: "/",
  },
  {
    iconSrc: "/images/marketing-agency/service_icon_1.svg",
    title: "Expose Recalls & Buybacks",
    subTitle: "Examine records of known vehicle defects",
    btnText: "Check Vin",
    btnUrl: "/",
  },
  {
    iconSrc: "/images/marketing-agency/service_icon_2.svg",
    title: "Run US Vehicle History",
    subTitle: "Check any matching vehicle records in the United States",
    btnText: "See More",
    btnUrl: "/service/service-details",
  },
];
const funfactData = [
  { title: "Happy Customers", number: "22k" },
  { title: "Work’s Completed", number: "15k" },
  { title: "Skilled Team Members", number: "121" },
  { title: "Most Valuable Awards", number: "15" },
];

const faqData = [
  {
    title: "01. What is Audit Your Vin vehicle history report",
    content:
      "A AuditYourVin vehicle history report serves as a meticulous compilation of a vehicle’s past, encompassing crucial details such as title issues, odometer readings, accident history, structural damage, liens, theft records, and manufacturer recalls. Our core objective is to empower participants in the automotive market by providing transparent and accessible information through a Vin Check. VinAudit aims to facilitate informed decision-making for both buyers and sellers, focusing on fraud prevention and fostering trust. We strive to make comprehensive vehicle history reports, including Vin Check, an essential tool in the automotive landscape. The service is meticulously designed to be an efficient and cost-effective alternative, aligning with AuditYourVin’s commitment to enhancing transparency, reliability, and confidence in every vehicle transaction.",
  },
  {
    title: "02. Is AuditYourVin history report legit?",
    content:
      "Yes, the AuditYourVin Vehicle History Report is a legitimate vehicle data service. The AuditYourVin's accreditation as an official access provider for the NMVTIS ensures adherence to rigorous standards set by the federal government, emphasizing the credibility and accuracy of the information in the reports. Through the NMVTIS, AuditYourVin sources its data from a centralized database that aggregates information from state motor vehicle agencies, salvage yards, and insurance carriers, enhancing the reliability of the provided vehicle history information. This legitimacy makes each AuditYourVin car report a trusted and reliable resource on vehicles in North America.",
  },
  {
    title: "03. Do AuditYourVin reports have information on every vehicle?",
    content:
      "While AuditYourVin strives to offer information on a broad spectrum of vehicles, there are instances where certain details may not be available or may have limited coverage. Factors such as state regulations and reporting practices can influence the completeness of the information in the report. For the most accurate and up-to-date information, we recommend checking the availability of reports for specific vehicles through our platform. As an NMVTIS-compliant provider, we can provide all the data reported to the NMVTIS such as title records, salvage records, and title brands. AuditYourVin also integrates data outside of NMVTIS, including theft records, lien records, impound/export records, and sale records.",
  },
  {
    title: "04. Who can benefit form AuditYourVin's vehicle history reports?",
    content:
      "We provide vehicle history reports for a diverse audience, including consumers seeking trustworthy information before purchase, sellers aiming for transparent transactions, and industry professionals navigating the complexities of the automotive market. Our goal is to empower all stakeholders with accurate and accessible insights into a vehicle’s past.   Our reports are available to anyone who needs the history of a used vehicle. Whether you’re in the process of buying a used vehicle and want to uncover its complete history and potential hidden issues or if you’re a seller looking to demonstrate the value of your car to a private party, our reports serve as a valuable resource. By providing comprehensive insights, we empower buyers with confidence, ensuring they are well informed about the vehicle’s history before making a purchase.",
  },
  {
    title: "05. What are the Data Products offered by AuditYourVin?",
    content:
      "AuditYourVin stands at the forefront of providing comprehensive Automotive Data Solutions that cater to diverse automotive busines needs. Advanced Vehicle Data APIs, streamlining the integration of specifications, market values, vehicle history, and automotive images for efficient operations. Additionally, AuditYourVin's Market Data Feeds provide real-time market intelligence with daily updates on millions of listings and insights from thousands of dealerships. With a commitment to accuracy and reliability, AuditYourVin empowers users and businesses across the automotive industry, ensuring transparency and informed decision-making.",
  },
];
const brandList = [
  {
    logoSrc: "/images/car1.png",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/car2.jpeg",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/car3.png",
    logoAlt: "Brand",
  },
  {
    logoSrc: "/images/car4.png",
    logoAlt: "Brand",
  },
];

export default function MarketingAgencyPage({ darkMode }) {
  pageTitle("Home");
  const [show, setShow] = React.useState(false);
  const [dShow, setDshow] = React.useState(false);
  const [selectedPackage, setSelectedPackage] = React.useState(0.00);
  const [errors, setErrors] = React.useState({});
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    vinNumber: "",
    package: selectedPackage,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Invalid email address";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch("https://getform.io/f/aqonlmza", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log(
          "Your details has been submitted successfully. Someone from our team will get back you shortly!"
        );

        toast.success(
          "Your details has been submitted successfully. Someone from our team will get back you shortly!"
        );
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          address: "",
          vinNumber: "",
          package: selectedPackage,
        });
        setErrors({});
        handledClose();
      } else {
        console.error("Error submitting form:", response.statusText);
        toast.error("Error submitting form. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form. Please try again later.");
    }
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handledOpen = () => {
    setDshow(true);
  };

  const handledClose = () => {
    setDshow(false);
  };

  const handlePackageSelect = (amount) => {
    setSelectedPackage(amount);
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: selectedPackage,
            currency_code: "USD",
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      // Handle successful payment
      console.log(details, data);
      // Perform actions after successful payment (e.g., order confirmation, email notification)
    });
  };

  const onError = (err) => {
    console.error(err);
  };

  return (
    <>
      <HeroStyle2
        miniTitle="Canadian Based Vehicle Inspection"
        title="EVERY VEHICLE HAS A STORY"
        subTitle="While basking in the golden glow of a potential new car, shrouded in the excitement of shiny paint and plush interiors, don't forget to delve deeper and utilize a comprehensive inspection service, one that scrutinizes not only the title and ownership history but also probes for over 100 potential hidden issues, ensuring you don't end up saddled with unforeseen repair bills and the lingering consequences of past accidents."
        thumbnailSrc="/images/toyo.png"
        handleOpen={handledOpen}
        mikeIcon={
          darkMode ? "/images/icons/mike_dark.svg" : "/images/icons/mike.svg"
        }
      />
      <Spacing lg="84" md="50" />
      <div className="container">
        <Brands data={brandList} />
      </div>
      {show && (
        <Dialog
          open={show}
          onClose={handleClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "400px",
                margin: "0 auto",
                padding: "25px 30px",
                background: "#D3D3D3",
              },
            },
          }}
        >
          <div>
            <div>
              <div>
                <input
                  type="text"
                  placeholder="Enter your vin number"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <a
              onClick={handleClose}
              className="cs_btn cs_style_1"
              style={{ marginTop: "20px" }}
            >
              <span>Submit</span>
            </a>
          </div>
        </Dialog>
      )}
      {dShow && (
        <Dialog
          open={dShow}
          onClose={handledClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "400px",
                margin: "0 auto",
                padding: "25px 30px",
                background: "#D3D3D3",
              },
            },
          }}
        >
          <div>
            <img src="/images/logo.png" alt="logo" />
          </div>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <input
                type="text"
                value={formData.name}
                name="name"
                onChange={handleChange}
                placeholder="Enter your name"
                style={{ width: "100%", height: "40px" }}
                required
              />
              <input
                value={formData.email}
                onChange={handleChange}
                name="email"
                type="email"
                placeholder="Enter your email"
                style={{ width: "100%", height: "40px" }}
                required
              />
              <input
                value={formData.phoneNumber}
                onChange={handleChange}
                name="phoneNumber"
                type="text"
                placeholder="Enter your phone number"
                style={{ width: "100%", height: "40px" }}
                required
              />
              <input
                value={formData.address}
                onChange={handleChange}
                name="address"
                type="text"
                placeholder="Enter your address"
                style={{ width: "100%", height: "40px" }}
                required
              />
              <input
                value={formData.vinNumber}
                onChange={handleChange}
                name="vinNumber"
                type="text"
                placeholder="Enter your vin number"
                style={{ width: "100%", height: "40px" }}
                required
              />
              <select
                onChange={handleChange}
                value={formData.package}
                name="package"
                required
              >
                <option>Select your package</option>
                <option
                  id="package1"
                  name="package"
                  value="25.00"
                  onChange={() => handlePackageSelect(25.00)}
                >
                  $25
                </option>
                <option
                  id="package2"
                  name="package"
                  value="50.00"
                  onChange={() => handlePackageSelect(50.00)}
                >
                  $50
                </option>
                <option
                  id="package3"
                  name="package"
                  value="85.00"
                  onChange={() => handlePackageSelect(85.00)}
                >
                  $85
                </option>
              </select>
              {/* {errors && <p style={{ color: "red" }}>{errors}</p>} */}
              {/* <button className="cs_btn cs_style_1" type="submit">
                Submit
              </button> */}
              <PayPalScriptProvider
                options={{
                  "client-id":
                    "AaEjQgd80pF5_JBr6by0OcQ9yh8qmmcC4vEc0desTuE9WvWnG4ohJGxML6q0K2RMMAjDd3ECZkrhK0Ka",
                  // deferLoading: true,
                }}
              >
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                  onError={onError}
                />
              </PayPalScriptProvider>
            </div>
          </form>
          {/* {selectedPackage && (
            <PayPalScriptProvider
              options={{
                "client-id":
                  "AaEjQgd80pF5_JBr6by0OcQ9yh8qmmcC4vEc0desTuE9WvWnG4ohJGxML6q0K2RMMAjDd3ECZkrhK0Ka",
              }}
            >
              <PayPalButtons
                createOrder={createOrder}
                onApprove={onApprove}
                onError={onError}
              />
            </PayPalScriptProvider>
          )} */}
        </Dialog>
      )}
      <ToastContainer />
      <section className="cs_p76_full_width" id="service">
        <Spacing lg="143" md="75" />
        <SectionHeading
          title="In partnership with the Canadian government, non-profit, and auto industry entities, Audit Your Vin delivers a full vehicle history report for a thorough vehicle assessment"
          subTitle="THE CANADIAN VEHICLE HISTORY AUTHORITY"
        />
        <Spacing lg="85" md="45" />
        <ServiceSlider data={servideData} />
      </section>
      <section>
        <div className="cs_height_150 cs_height_lg_75" />
        <AboutStyle2
          thumbnailSrc1="/images/r.jpg"
          thumbnailSrc2="/images/b.jpg"
          uperTitle="Who We Are"
          title="Our Reports Will Check For"
          //   subTitle="Our team, specializing in strategic digital marketing, partners with the world's leading brands. Breaking from the norm, we push boundaries and merge imaginative thinking, consumer behavior,
          // and data-driven design with advanced technology to deliver unparalleled brand experiences."
          featureList={[
            "Previous Accidents",
            "Branded Title History",
            "Lien & Repossession Records",
            "Sale Records",
            "Salvage & Reconstruction",
            "Vehicle Specifications",
            "Impound and Towing Events",
            "Lemon Check",
            "Reported Odometer Readings",
            "Theft & Recovery",
            "Records of Taxi or Police Use",
          ]}
          btnText="Check Vin"
          btnUrl={handledOpen}
        />
      </section>
      <div className="container">
        <Spacing lg="125" md="70" />
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="85" md="45" />
      <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            subTitle="What You Will Find in a Vin Report"
            title=""
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <div className="row cs_gap_y_45">
            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_1.svg"
                title="Fast Service"
                subTitle="Get your reports within minutes with our fast searching capabilities and quick sign up process. Don’t wait days for your report on other sites. Go with VinreportsPro!"
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_2.svg"
                title="Reliable"
                subTitle="Get accurate information every time with our incredibly reliable service. Need help? Reach out to our support team via live chat or email."
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_3.svg"
                title="In-Depth"
                subTitle="Find exactly what you’re looking for with our reports. We search through many databases and data sources to get you comprehensive and in-depth reports."
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_4.svg"
                title="Affordable"
                subTitle="With our subscription based service you get up to 15 free reports a month and unlimited access to our search tool, all for a very affordable price!"
              />
            </div>
          </div>
        </div>
        <Spacing lg="143" md="75" />
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="60" />
      </section>
      <section>
        <div className="container">
          <Cta
            title="Industry giants trust Audit Your Vin. So should you."
            btnText="Contact Us"
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
      <Spacing lg="135" md="70" />
      <Marquee text="EVERY VEHICLE HAS A STORY" />
      <Spacing lg="135" md="80" />
    </>
  );
}
